<template>
  <div v-if="SearchStatistic.queries" class="flex gap-4">
    <button @click="statisticTab = true" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">Успешность, %</button>
    <button @click="statisticTab = false" class="flex px-7 py-4 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500">Запросы</button>
    <div v-if="!statisticTab" class="flex items-center gap-4 ml-32">
      <div>
        <input type="checkbox" id="isSuccess" v-model="isSuccess"
               class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-blue-600 checked:bg-blue-700 focus:ring-blue-500 focus:ring-2"/>
        <label for="isSuccess" class="ml-2 text-sm font-medium text-gray-900">Успешные</label>
      </div>
      <div>
        <input type="checkbox" id="isFailure" v-model="isFailure"
               class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-blue-600 checked:bg-blue-700 focus:ring-blue-500 focus:ring-2"/>
        <label for="isFailure" class="ml-2 text-sm font-medium text-gray-900">Неуспешные</label>
      </div>
    </div>
  </div>

  <div v-if="statisticTab" class="bg-white rounded-lg overflow-hidden">
    <div class="grid grid-cols-5 bg-gray-200 text-[10px] px-8 py-4">
      <div class="col-span-1"></div>
      <div class="col-span-1">Количество запросов</div>
      <div class="col-span-1">Успешных</div>
    </div>
    <div v-if="SearchStatistic.infoFilter.length" class="border border-gray-9">
      <div v-for="item in SearchStatistic.infoFilter" class="grid grid-cols-5 bg-white text-[10px] px-8 py-4 border-b border-b-gray-2 last:border-none !text-sm">
        <div class="col-span-1 flex items-center gap-2">
          <div class="w-2 h-2 rounded-full" :style="`background: ${item.color}`"></div>
          <div class="">{{item.name}}</div>
        </div>
        <div class="col-span-1">{{item.count}}</div>
        <div class="col-span-1">{{item?.percentage ? item?.percentage+'%' : '0%'}}</div>
        <div class="col-span-1">
          <svg @click="setActiveCheckbox(item.type, [item.id ?? item.entity_id])" class="w-6 h-6 text-gray-800 dark:text-white cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path d="M13.5 2c-.178 0-.356.013-.492.022l-.074.005a1 1 0 0 0-.934.998V11a1 1 0 0 0 1 1h7.975a1 1 0 0 0 .998-.934l.005-.074A7.04 7.04 0 0 0 22 10.5 8.5 8.5 0 0 0 13.5 2Z"/>
            <path d="M11 6.025a1 1 0 0 0-1.065-.998 8.5 8.5 0 1 0 9.038 9.039A1 1 0 0 0 17.975 13H11V6.025Z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!statisticTab" class="bg-white rounded-lg overflow-hidden">
    <div class="grid grid-cols-5 bg-gray-200 text-[10px] px-8 py-4">
      <div class="col-span-1">Запрос</div>
      <div class="col-span-1">Успешный</div>
      <div class="col-span-1">Категория</div>
      <div class="col-span-1">Марка</div>
      <div class="col-span-1">Модель</div>
    </div>
    <div class="border border-gray-9">
      <div v-for="item in queries" class="grid grid-cols-5 bg-white text-[10px] px-8 py-4 border-b border-b-gray-2 last:border-none !text-sm">
        <div class="col-span-1">{{item.query}}</div>
        <div class="col-span-1">{{item.result ? 'Да' : 'Нет' }}</div>
        <div class="col-span-1">{{item.category}}</div>
        <div class="col-span-1">{{item.make}}</div>
        <div class="col-span-1">{{item.model}}</div>
      </div>
    </div>
    <div class="p-5 flex">
      <button class="px-4 py-2 bg-gray-400 rounded-md text-white font-medium hover:bg-blue-500" @click="downloadFile">Скачать файл</button>
      <div class="flex-col p-5 w-1/2">
        <nav v-if="SearchStatistic.pagination && SearchStatistic.pagination.lastPage > 1">
          <ul class="inline-flex -space-x-px">
            <li v-for="(link, index) of SearchStatistic.pagination.links">
              <a v-if="link.url" href="#"
                 @click.prevent="entityPaginate(link.url)"
                 :class="classInputPagination(index, link.active)"
                 class="px-3 py-2 border border-gray-300">
                <span v-html="link.label"></span>
              </a>
              <a v-else
                 :class="classInputPagination(index, link.active)"
                 class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                <span v-html="link.label"></span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {SearchStatisticStore} from "../../../stores/SearchStatisticStore";
import {ref, watch, computed} from "vue";

const SearchStatistic = SearchStatisticStore()
const isUpdate = computed(() => SearchStatistic.isLoaded);
const statisticTab = ref(true)
const isSuccess = ref(true)
const isFailure = ref(true)
const queries = ref([])

watch([isSuccess, isFailure], () => {
  queries.value = SearchStatistic.queries

  let params = {
    isSuccess: isSuccess.value,
    isFailure: isFailure.value,
  }

  SearchStatistic.getQueries(params).then(() => {
    queries.value = SearchStatistic.queries
  });
})

watch(isUpdate, () => {
    statisticTab.value = true
    isSuccess.value = true
    isFailure.value = true

    if (SearchStatistic.queries && SearchStatistic.queries.length) {
        queries.value = SearchStatistic.queries
    }
})

const entityPaginate = (url) => {
  if (url) {

    let params = {
      isSuccess: isSuccess.value,
      isFailure: isFailure.value,
    }

    SearchStatistic.getQueries(params, url).then(() => {
      queries.value = SearchStatistic.queries
    });
  }
}

const setActiveCheckbox = (code = '', selects = []) => {
  if (code == 'queryType') {
    SearchStatistic.type = selects[0]
    SearchStatistic.getDefaultFilters()
  } else {
    SearchStatistic.addParamReactiveFilter(code, selects)
    SearchStatistic.applyParamFilter()
  }
}

const downloadFile = () => {
    SearchStatistic.downloadFile().then(() => {
        const link = document.createElement('a');
        link.href = SearchStatistic.file;
        link.setAttribute('download', '');
        link.setAttribute('target', '_blank');
        console.log(link)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
}

const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === SearchStatistic.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});

</script>

<style scoped>

</style>
<template>
  <div class="px-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <!-- Техника -->
      <div class="mb-4 h-full bg-gray-50 border border-gray-300 rounded-lg flex flex-col">
        <!-- Header -->
        <div class="flex justify-between items-center mb-4 p-4 border-b border-gray-300">
          <div>
            <h3 class="mb-2 text-xl font-bold text-gray-900">Сцепки для маркетингового позиционирования</h3>
          </div>
          <div class="flex-shrink-0">
            <a @click.prevent="createMarketing" href="#" class="p-2 text-sm font-medium rounded-lg text-blue-700 hover:bg-gray-100">&plus; Добавить сцепку</a>
          </div>
        </div>
        <!-- Search -->
        <div class="p-2 py-0">
          <div class="items-center mb-3 flex w-full">
            <form @keyup="search" method="GET" class="lg:block pl-4 pr-4 w-full">
              <div class="relative">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="currentColor"
                       viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"></path>
                  </svg>
                </div>
                <input v-model="searchInput"
                       type="text"
                       placeholder="Поиск сцепки по марке"
                       class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5">
              </div>
            </form>
          </div>
        </div>
        <div class="p-4 py-0 px-6">
          <div class="items-center mb-3 w-full">
            <input-select-lite :options="brandOptions" v-model="brand" label="Бренд"/>
          </div>
        </div>
        <div class="p-4 py-0 px-6">
          <div class="items-center mb-3 w-full">
            <input-search
                v-model="product"
                :suggestions="marketingStore.productOptions"
                @selected="handlerSelectedProduct"
                @keyup="searchProduct"
                label="Продукт"/>
          </div>
        </div>

        <!-- Table -->
        <div class="overflow-x-auto border-b">
          <div class="inline-block min-w-full align-middle height-rows-area">
            <table class="min-w-full divide-y divide-gray-300 table-fixed">
              <thead class="bg-gray-100 sticky top-0">
              <tr>
                <th scope="col" class="p-4">
                  <div class="flex items-center">
                    <input v-model="checkboxAllSelected"
                           @click="selectAllCheckbox"
                           id="checkbox-all" type="checkbox"
                           class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                    <label for="checkbox-all" class="sr-only">checkbox</label>
                  </div>
                </th>
                <th scope="col" class="p-4 pl-0 text-xs font-medium text-left text-gray-400">
                  Категория
                </th>
                <th scope="col" class="p-4 pl-0 text-xs font-medium text-left text-gray-400">
                  Марка
                </th>
                <th scope="col" class="p-4 pl-0 text-xs font-medium text-left text-gray-400">
                  Тип узла
                </th>
                <th scope="col" class="p-4 pl-0 text-xs font-medium text-left text-gray-400">
                  Тип топлива
                </th>
                <th scope="col" class="p-4 pl-0 text-xs font-medium text-left text-gray-400">
                  Продуктов
                </th>
                <th scope="col" class="p-4"></th>
              </tr>
              </thead>

              <tbody v-if="loadData" class="divide-y divide-gray-200">
              <tr>
                <td class="pl-4 w-4 tr-btn" colspan="4">
                  <spinner></spinner>
                </td>
              </tr>
              </tbody>
              <tbody v-else class="divide-y divide-gray-200">
              <tr v-for="marketing of marketingStore.marketings" :key="marketing.id"
                  :class="{ 'selected-row': isActiveMarketing(marketing.id), 'selected-for-delete': isSelectForDelete(marketing.id), 'deleted': marketing.deleted }">
                <td class="pl-4 w-4 tr-btn">
                  <div class="flex items-center">
                    <input v-if="marketing.deleted" v-model="checkboxDeletedIds" :value="marketing.id" type="checkbox"
                           class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                    <input v-else v-model="checkboxIds" :value="marketing.id" type="checkbox"
                           class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                  </div>
                </td>
                <td @click.prevent="getDataProducts(marketing)" class="text-base text-gray-900 tr-btn pr-4">
                  <p class="text-base font-medium text-gray-700">{{ marketing.category_group  }}</p>
                </td>
                <td @click.prevent="getDataProducts(marketing)" class="tr-btn pr-4">
                  <p class="text-base font-medium text-gray-700">{{ marketing.make }}</p>
                </td>
                <td @click.prevent="getDataProducts(marketing)" class="tr-btn pr-4">
                  <p class="text-base font-medium text-gray-700">{{ marketing.compCategory }}</p>
                </td>
                <td @click.prevent="getDataProducts(marketing)" class="tr-btn pr-4">
                  <p class="text-base font-medium text-gray-700">{{ marketing.fuel }}</p>
                </td>
                <td @click.prevent="getDataProducts(marketing)" class="tr-btn pr-4">
                  <p class="text-base font-medium text-gray-700">{{ marketing.products_count }}</p>
                </td>
                <td class="space-x-2 whitespace-nowrap text-center">
                  <button @click="openDialog(marketing.id)" type="button" class="py-2 px-3 text-sm font-medium text-center">
                    <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
                      <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
                    </svg>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Footer -->
        <div class="flex justify-between items-center pt-3">
          <div class="flex-col p-3 w-1/3">
            <template v-if="searchInput && !loadData">
              <span class="text-gray-500 leading-10">Найдено</span> {{ marketingStore.marketings.length }}
            </template>
          </div>
          <div class="flex-col p-3 w-2/3 text-right">
            <button v-if="checkboxDeletedIds.length" @click="revertMarketings()" type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blu-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center ">
              <svg class="mr-2 -ml-1 w-5 h-5" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"></path>
              </svg>
              Восстановить
            </button>
            <button v-if="checkboxIds.length" @click="deleteMarketings()" type="button"
                    class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center ">
              <svg class="mr-2 -ml-1 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
              </svg>
              Удалить
            </button>
            <nav v-if="!checkboxIds.length && !checkboxDeletedIds.length && marketingStore.pagination && marketingStore.pagination.lastPage > 1">
              <ul class="inline-flex -space-x-px">
                <li v-for="(link, index) of marketingStore.pagination.links">
                  <a v-if="link.url" href="#"
                     @click.prevent="marketingsPaginate(link.url)"
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 border border-gray-300">
                    <span v-html="link.label"></span>
                  </a>
                  <a v-else
                     :class="classInputPagination(index, link.active)"
                     class="px-3 py-2 ml-0 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
                    <span v-html="link.label"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <!-- Продукты - правая часть страницы -->
      <div class="mb-4 h-full bg-white border border-gray-200 rounded-lg">
        <!-- Header -->
        <div class="flex justify-between items-center p-4 border-b border-gray-300">
          <div>
            <h3 class="mb-2 text-xl font-bold text-gray-900">Продукты</h3>
          </div>
          <div v-if="currentMarketing && marketingStore.productOptionsPopup.length" class="flex-shrink-0">
            <a @click.prevent="createProduct" href="#" class="p-2 text-sm font-medium rounded-lg text-blue-700 hover:bg-gray-100">&plus; Добавить продукт</a>
          </div>
        </div>

        <!-- Table -->
        <div class="overflow-x-auto border-b h-4/5">
          <div class="inline-block min-w-full align-middle height-rows-area">
            <div class="min-w-full">
              <div v-if="marketingStore.showNoProductsMessage" class="w-5/6 text-sm font-normal text-red-400 p-7">Для выбранной сцепки нет подходящих продуктов</div>
              <div v-if="!marketingStore.showNoProductsMessage" class="w-5/6 text-sm font-light text-gray-400 p-7">Для установки приоритетов расставьте продукты в&nbsp;нужном порядке методом Drag-and-Drop</div>
              <div class="border-y border-gray-300 bg-gray-100">
                <div class="grid grid-cols-viscosity">
                  <div class="p-4 h-full flex items-center" scope="col">
                    <div class="flex items-center">
                      <input v-model="checkboxAllProductsSelected"
                             @click="selectAllProductsCheckbox"
                             id="checkbox-all" type="checkbox"
                             class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                    </div>
                  </div>
                  <div class="p-4 text-left font-light text-gray-400">
                    Название
                  </div>
                  <div class="p-4 text-right font-light text-gray-400">
                    Приоритет
                  </div>
                  <div class=""></div>
                </div>
              </div>
              <div v-if="currentMarketing" class="divide-y divide-gray-200">
                <draggable v-model="products" item-key="id" class="transition-colors" @choose="deactivateProductMessage">
                  <template #item="{element, index}">
                    <div class="grid grid-cols-viscosity border-t-0 border-y border-gray-300 active:!bg-gray-100"
                         :class="{'bg-neutral-100 opacity-50': !element.status}">
                      <div class="p-4 w-4 h-full flex items-center">
                        <div class="flex items-center">
                          <input v-model="productIds" :value="element.id" aria-describedby="checkbox-1" type="checkbox"
                                 class="w-4 h-4 bg-gray-50 rounded border-gray-300 focus:ring-3 focus:ring-primary-300">
                        </div>
                      </div>
                      <div class="p-4">
                        <span>{{element.name}}</span>
                      </div>
                      <div class="text-right p-4">{{index + 1}}</div>
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- Footer -->
        <div class="flex justify-between items-center pt-3 h-20">
          <div class="flex-col p-7 w-2/3">
            <span class="text-gray-500">Найдено</span> {{ marketingStore.products.length }}
            <span class="ml-4 text-green-400 text-sm" v-if="isShowAddProductMessage">Продукты добавлены в список</span>
            <span class="ml-4 text-red-400 text-sm" v-if="isShowDeleteProductMessage">Продукты удалены из списка</span>
            <span class="ml-4 text-green-400 text-sm" v-if="isShowUpdateSortMessage">Изменения сохранены</span>
          </div>
          <div v-if="productIds.length" class="flex-col p-7 w-1/3 text-right">
            <button @click="deleteProducts" type="button"
                    class="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              <svg class="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clip-rule="evenodd"></path>
              </svg>
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MARKETING POPUP  -->
  <modal v-if="showDialog" @closeDialog="closeDialog" :load-data="loadDataInModal">
    <template #title>Редактировать маркетинговое предложение</template>
    <template #body>
      <spinner v-if="loadData"></spinner>
      <form v-if="!loadData && marketingStore.marketing" action="#">
        <input type="hidden" name="id" v-model="marketingStore.marketing.id">

        <div class="grid gap-4 mb-4 sm:grid-cols-1">
          <div>
            <input-select-lite :v$="v$.category" :options="categoryGroupOptions" v-model="marketingStore.marketing.category" label="Категория"/>
            <p v-if="v$.category.$error" class="mt-2 text-sm text-red-600"> {{ v$.category.$errors[0].$message }} </p>
          </div>
          <div>
            <input-select-lite :v$="v$.make" :options="makeOptions" v-model="marketingStore.marketing.make" label="Марка"/>
            <p v-if="v$.make.$error" class="mt-2 text-sm text-red-600"> {{ v$.make.$errors[0].$message }} </p>
          </div>
          <div>
            <input-select-lite :v$="v$.compCategory" :options="compCategoryOptions" v-model="marketingStore.marketing.compCategory" label="Тип узла"/>
            <p v-if="v$.compCategory.$error" class="mt-2 text-sm text-red-600"> {{ v$.compCategory.$errors[0].$message }} </p>
          </div>
          <div>
            <input-select-lite :v$="v$.fuel" :options="fuelOptions" v-model="marketingStore.marketing.fuel" label="Тип топливо"/>
            <p v-if="v$.fuel.$error" class="mt-2 text-sm text-red-600"> {{ v$.fuel.$errors[0].$message }} </p>
          </div>
        </div>

        <div v-if="marketingStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
          {{ marketingStore.error }}
        </div>

        <div class="flex items-center space-x-4">
          <button @click.prevent="saveMarketing" type="submit"
                  class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Сохранить
          </button>
          <button @click="closeDialog" type="button"
                  class="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            <svg class="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"></path>
            </svg>
            Отмена
          </button>
        </div>
      </form>
    </template>
  </modal>

  <!-- PRODUCT POPUP  -->
  <modal v-if="showDialogProduct" @closeDialog="closeDialogProduct" classes="overflow-visible">
    <template #title>Добавить продукт</template>
    <template #body>
      <form action="#">
        <input type="hidden" name="marketing_id" v-model="currentMarketing.value">
        <div class="grid gap-4 mb-4 sm:grid-cols-1">
          <div>
            <input-search
                :v$="v2$.productPopup"
                v-model="marketingStore.product.productPopup"
                :suggestions="marketingStore.productOptionsPopup"
                @selected="handlerSelectedProductPopup"
                @keyup="searchProductPopup"
                classes="overflow-visible"
                label="Продукт"/>

            <p v-if="v2$.productPopup.$error" class="mt-2 text-sm text-red-600"> {{ v2$.productPopup.$errors[0].$message }} </p>
          </div>
        </div>

        <div v-if="marketingStore.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
          {{ marketingStore.error }}
        </div>

        <div class="flex items-center space-x-4">
          <button @click.prevent="saveProduct" type="submit"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Сохранить
          </button>
          <button @click="closeDialogProduct" type="button"
                  class="text-blue-600 inline-flex items-center hover:text-white border border-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Отмена
          </button>
        </div>
      </form>
    </template>
  </modal>

</template>

<script setup>
import {useMarketingStore} from "../../stores/MarketingStore.js";
import Spinner from "../flowbite/spinner.vue";
import Modal from "../flowbite/modal.vue";
import {computed, reactive, ref, watch} from "vue";
import InputSearch from "../flowbite/form/inputSearch.vue";
import useVuelidate from '@vuelidate/core'
import {required} from '../../utilits/custom-validators.js'
import InputSelectLite from "../flowbite/form/inputSelectLite.vue";
import draggable from 'vuedraggable'
import {useRoute, useRouter} from 'vue-router'

const route = useRoute()
const router = useRouter()

const marketingStore = useMarketingStore();
const showDialog = ref(false);
const showDialogProduct = ref(false);
const loadData = ref(true);
const loadDataInModal = ref(true);

const categoryGroupOptions = ref([]);
const makeOptions = ref([]);
const compCategoryOptions = ref([]);
const fuelOptions = ref([]);
const brandOptions = ref([]);

const brand = ref('');
const make_id = ref('');
const comp_category_id = ref('');
const fuel_id = ref('');
const category_group_id = ref('');
const product = ref('');
const productId = ref('');
const productPopupId = ref('');
const productIds = ref([]);
const products = ref([]);

const currentMarketing = ref(null);

const checkboxAllSelected = ref(false);
const checkboxIds = ref([]);
const checkboxDeletedIds = ref([]);

const checkboxAllProductsSelected = ref(false);

const isShowUpdateSortMessage = ref(false);
const isShowAddProductMessage = ref(false);
const isShowDeleteProductMessage = ref(false);

const searchInput = ref(null);

//Первоначальные данные
marketingStore.getDirectoriesOptions().then(() => {
  categoryGroupOptions.value = [...categoryGroupOptions.value, ...marketingStore.categoryGroups];
  makeOptions.value = [...makeOptions.value, ...marketingStore.makes];
  compCategoryOptions.value = [...compCategoryOptions.value, ...marketingStore.compCategories];
  fuelOptions.value = [...fuelOptions.value, ...marketingStore.fuels];
  brandOptions.value = [...brandOptions.value, ...marketingStore.brands];

  brand.value = brandOptions.value[0].id

  if (route.query.brand_id) {
    brand.value = route.query.brand_id
  }
});

const getMarketings = (isDataLoad = true) => {
  if (isDataLoad) {
    loadData.value = true;
  }

  let params = {
    search: searchInput.value,
    filter: {
      product_id: productId.value,
      brand_id: brand.value,
    }
  }

  marketingStore.getMarketings(params).then(() => {
    if (isDataLoad) {
      loadData.value = false;
    }
  });
}

watch(products, () => {
  if (currentMarketing.value !== null) {
    marketingStore.products = products.value
    marketingStore.sendSortProducts(currentMarketing.value, products.value).then(() => {
      if (marketingStore.isSortUpdated) {
        isShowUpdateSortMessage.value = true;
        isShowAddProductMessage.value = false;
        isShowDeleteProductMessage.value = false;
      }
    })
  }
})

watch(brand, () => {
  product.value = '';
  currentMarketing.value = null;
  marketingStore.products = [];

  let params = {
    search: searchInput.value,
    filter: {
      brand_id: brand.value,
    }
  }
  marketingStore.getProducts(params)

  if (route.query.id) {
    productId.value = route.query.id
    product.value = marketingStore.productOptions.find(elem => elem.id == productId.value).name
    router.replace({ query: {} })
  }

  getMarketings();
})


watch([product], () => {

  if (product.value == '') {
    productId.value = '';
    getMarketings();
  }
})

//Валидация
const rules = computed(() => ({
  category: {required},
  make: {required},
  compCategory: {required},
  fuel: {required},
}));

let state = reactive({
  category: "",
  make: "",
  compCategory: "",
  fuel: "",
});

const rulesPopup = computed(() => ({
  brand: {required},
  productPopup: {required},
}));

let statePopup = reactive({
  brand: "",
  productPopup: "",
});

watch(marketingStore, (marketingStore) => {
  if (marketingStore.marketing) {
    state.category = marketingStore.marketing.category;
    state.make = marketingStore.marketing.make;
    state.compCategory = marketingStore.marketing.compCategory;
    state.fuel = marketingStore.marketing.fuel;
  }

  if (marketingStore.product) {
    statePopup.brand = brand;
    statePopup.productPopup = marketingStore.product.productPopup;
  }

  products.value = marketingStore.products
});

const v$ = useVuelidate(rules, state)
const v2$ = useVuelidate(rulesPopup, statePopup)

const createMarketing = () => {
  loadDataInModal.value = true;
  showDialog.value = true
  marketingStore.setEmptyMarketing();
  loadDataInModal.value = false;
}

const createProduct = () => {
  showDialogProduct.value = true
  marketingStore.setEmptyProduct();
}

//Модальное окно
const openDialog = (id) => {
  loadDataInModal.value = true;
  showDialog.value = true

  marketingStore.getMarketingById(id)
      .then(() => {
        loadDataInModal.value = false;
      });
}

const closeDialog = () => {
  showDialog.value = false;
  v$.value.$reset();
  marketingStore.resetMarketing();
  marketingStore.error = null;
}

const closeDialogProduct = () => {
  showDialogProduct.value = false;
  v2$.value.$reset();
  marketingStore.resetProduct();
  marketingStore.error = null;
}

// Работа с данными
const saveMarketing = () => {

  marketingStore.error = null;

  v$.value.$validate()

  let params = {
    search: searchInput.value,
    filter: {
      product_id: productId.value,
      brand_id: brand.value,
    }
  }

  if (!v$.value.$error) {
    loadDataInModal.value = true;

    let data = Object.assign(marketingStore.marketing, {brandId: brand.value})

    marketingStore.storeMarketing(data, params)
        .then(() => {
          loadDataInModal.value = false;
        });

    v$.value.$reset();
    showDialog.value = false;
  }
}

const saveProduct = () => {

  marketingStore.error = null;

  v2$.value.$validate()


  if (!v2$.value.$error) {
    marketingStore.storeProduct({product_id :productPopupId.value}, currentMarketing.value, brand.value).then(() => {
      getMarketings(false);
    });
    v2$.value.$reset();
    showDialogProduct.value = false;
  }

}


// Работа с данными
const marketingsPaginate = (url) => {
  if (url) {
    getMarketings();
  }
}

const deleteMarketings = () => {
  marketingStore.deleteMarketings({ids: checkboxIds.value}).then(() => {
    getMarketings();
    checkboxIds.value = [];
    currentMarketing.value = null;
  })
}

const revertMarketings = () => {
  marketingStore.revertMarketings({ids: checkboxDeletedIds.value}).then(() => {
    getMarketings();
    checkboxDeletedIds.value = [];
    currentMarketing.value = null;
  })
}

const selectAllCheckbox = () => {
  checkboxIds.value = [];

  if (!checkboxAllSelected.value) {
    marketingStore.marketings.forEach((item, index) => {
      if (!item.deleted) {
        checkboxIds.value.push(item.id)
      }
    });
  }
}

const getDataProducts = (item) => {
  currentMarketing.value = item.id;
  make_id.value = item.make_id;
  comp_category_id.value = item.comp_category_id;
  fuel_id.value = item.fuel_id;
  category_group_id.value = item.category_group_id;

  let params = {
    filter: {
      brand_id: brand.value,
      make_id: item.make_id,
      category_group_id: item.category_group_id,
      comp_category_id: item.comp_category_id,
      fuel_id: item.fuel_id,
    }
  }

  marketingStore.getProductsByMarketingId(item.id, params).then(() => {
    products.value = marketingStore.products
  })

  marketingStore.getProductsPopup(params)
}

const isSelectForDelete = (id) => {

  let res = false;
  checkboxIds.value.forEach((item) => {
    if (item === id) {
      res = true;
    }
  })

  return res;
};

const isActiveMarketing = (id) => {
  return currentMarketing.value && id === currentMarketing.value;
};

let timeoutSearch = null;
const search = () => {
  clearTimeout(timeoutSearch);

  loadData.value = true;

  localStorage.setItem('marketingSearch', searchInput.value)

  //Очищаем правую вкладку
  currentMarketing.value = null;
  marketingStore.products = [];

  timeoutSearch = setTimeout(() => {
    getMarketings();
  }, 800);
};

let timeoutProduct = null;
const searchProduct = (text) => {
  clearTimeout(timeoutProduct);

  let params = {
    search: text,
    filter: {
      brand_id: brand.value,
    }
  }

  timeoutProduct = setTimeout(() => {
    marketingStore.getProducts(params)
  }, 800);
};

let timeoutProductPopup = null;
const searchProductPopup = (text) => {
  clearTimeout(timeoutProductPopup);

  let params = {
    search: text,
    filter: {
      brand_id: brand.value,
      make_id: make_id.value,
      category_group_id: category_group_id.value,
      comp_category_id: comp_category_id.value,
      fuel_id: fuel_id.value,
    }
  }

  timeoutProductPopup = setTimeout(() => {
    marketingStore.getProductsPopup(params)
  }, 800);
};

const handlerSelectedProduct = (id) => {
  productId.value = id;
  getMarketings();
}

const handlerSelectedProductPopup = (id) => {
  productPopupId.value = id;
}

const selectAllProductsCheckbox = () => {
  productIds.value = [];

  if (!checkboxAllProductsSelected.value) {
    marketingStore.products.forEach((item) => {
      productIds.value.push(item.id)

    });
  }
}

const deleteProducts = () => {
  marketingStore.deleteProducts(productIds.value, currentMarketing.value, brand.value).then(() => {
    getMarketings(false);
    productIds.value = []

    isShowUpdateSortMessage.value = false;
    isShowDeleteProductMessage.value = true;
    isShowAddProductMessage.value = false;
  })
}

const deactivateProductMessage = () => {
  isShowUpdateSortMessage.value = false;
}

// Стили
const classInputPagination = (index, active) => ({
  'rounded-l-lg': index === 0,
  'rounded-r-lg': (index + 1) === marketingStore.pagination.links.length,
  'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700': active,
  'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700': !active,
});
</script>
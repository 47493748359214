import {defineStore} from 'pinia'
import {ref} from 'vue';
import axios from "axios";

export const SearchStatisticStore = defineStore("searchStatisticStore", () => {
    const isLoaded = ref(true)
    const isLoadedReactive = ref(true)
    const activeParamFilter = ref({})
    const type = ref()
    const dateStart = ref()
    const dateEnd = ref()

    const total = ref(0)
    const infoFilter = ref([])
    const queries = ref([])
    const fullInfoFilter = ref([])
    const reactiveFilter = ref([])
    const staticFilter = ref([])
    const pagination = ref(null);
    const file = ref()

    const getDefaultFilters = (updateInfo = true) => {
        let params = Object.assign(activeParamFilter.value, {
            'queryType': type.value,
            'dateStart': dateStart.value,
            'dateEnd': dateEnd.value,
        })

        if (updateInfo) {
            getInfo(params)
        }

        getDataReactiveFilter(params)
    }

    const getInfo = async (param = {}) => {
        isLoaded.value = false
        await axios.post('/admin/search-statistics/data', {params: JSON.stringify(param)})
            .then((response) => {
                if (response.data?.data && response.data?.data?.list.length) {
                    total.value = response.data?.data?.total
                    infoFilter.value = response.data?.data.list
                    queries.value = response.data?.data.queries
                    pagination.value = response.data.data.pagination;
                    fullInfoFilter.value = response.data?.data.list.map(a => Object.assign({}, a));
                    infoFilter.value.map(el => {
                        return {...el}
                    })
                }
                else {
                    total.value = 0
                    infoFilter.value = []
                    fullInfoFilter.value = []
                }
            }).catch((error) => {
                total.value = 0
                infoFilter.value = []
                fullInfoFilter.value = []
                console.log(error);
            })
        isLoaded.value = true
    }

    const getQueries = async (params = {}, url = '/admin/search-statistics/queries') => {
        activeParamFilter.value = Object.assign(activeParamFilter.value, params);

        await axios.post(url, {
            params: JSON.stringify(activeParamFilter.value),
        })
            .then((response) => {
                if (response.data?.data) {
                    queries.value = response.data?.data.queries
                    pagination.value = response.data.data.pagination;
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    const getDataReactiveFilter = async (param = {}) => {
        isLoadedReactive.value = false
        await axios.post('/admin/search-statistics/filters', {params: JSON.stringify(param)})
            .then((response) => {
                reactiveFilter.value = response.data.data.dynamic
                staticFilter.value = response.data.data.static
            }).catch((error) => {
                reactiveFilter.value = []
                staticFilter.value = []
                console.log(error);
            })
        isLoadedReactive.value = true
    }

    const downloadFile = async () => {
        await axios.post('/admin/search-statistics/download', {
            params: JSON.stringify(activeParamFilter.value),
        })
            .then((response) => {
                file.value = response?.data?.data || undefined
            }).catch((error) => {
                console.log(error);
            })
    }

    //Обнуление фильтра
    const resetFilter = () => {
        activeParamFilter.value = {}
        type.value = ''
        dateStart.value = ''
        dateEnd.value = ''

        getDefaultFilters()
    }

    //Применение выбранных параметорв фильтра
    const applyParamFilter = (url = '/admin/search-statistics/data') => {
        let params = Object.assign(activeParamFilter.value, {
            'queryType': type.value,
            'dateStart': dateStart.value,
            'dateEnd': dateEnd.value,
        })

        getInfo(params, url)
    }

    //Активные параметры реактивного фильтра
    const addParamReactiveFilter = (code = '' , selects = []) => {
        activeParamFilter.value[code] = selects
        let flag = false
        Object.keys(activeParamFilter.value).forEach(key => {
            if (key !== 'fuel' && flag) {
                delete activeParamFilter.value[key]
            }
            if (key === code) flag = true
        })

        getDataReactiveFilter(activeParamFilter.value)
    }

    return {
        isLoaded,
        isLoadedReactive,
        infoFilter,
        queries,
        pagination,
        total,
        fullInfoFilter,
        reactiveFilter,
        staticFilter,
        activeParamFilter,
        type,
        dateStart,
        dateEnd,
        file,
        resetFilter,
        getDefaultFilters,
        downloadFile,
        applyParamFilter,
        getDataReactiveFilter,
        addParamReactiveFilter,
        getQueries,
    }
})
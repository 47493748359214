import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from '../stores/AuthStore.js'
import TheLoginPage from '../components/auth/TheLoginPage.vue'
import TheForgotPassword from '../components/auth/TheForgotPassword.vue'
import TheDashboard from '../components/pages/TheDashboard.vue'
import TheUsers from '../components/pages/TheUsers.vue'
import TheApiKeys from '../components/pages/TheApiKeys.vue'
import TheQvs from '../components/pages/TheQvs.vue'
import TheMarketings from '../components/pages/TheMarketings.vue'
import TheProducts from '../components/pages/TheProducts.vue'
import TheCompanies from '../components/pages/TheCompanies.vue'
import TheCompany from '../components/pages/TheCompany.vue'
import TheModels from '../components/pages/TheModels.vue'
import TheModel from '../components/pages/TheModel.vue'
import TheMake from '../components/pages/TheMake.vue'
import TheMakeEdit from '../components/pages/TheMakeEdit.vue'
import TheViscosity from '../components/pages/TheViscosity.vue'
import TheViscosityEdit from '../components/pages/TheViscosityEdit.vue'
import TheUses from '../components/pages/TheUses.vue'
import TheUse from '../components/pages/TheUse.vue'
import TheCoolTypes from '../components/pages/TheCoolTypes.vue'
import TheCoolType from '../components/pages/TheCoolType.vue'
import TheEngineBuilds from '../components/pages/TheEngineBuilds.vue'
import TheEngineBuild from '../components/pages/TheEngineBuild.vue'
import TheFuels from '../components/pages/TheFuels.vue'
import TheFuel from '../components/pages/TheFuel.vue'
import TheGearOperations from '../components/pages/TheGearOperations.vue'
import TheGearOperation from '../components/pages/TheGearOperation.vue'
import TheDriveTypes from '../components/pages/TheDriveTypes.vue'
import TheDriveType from '../components/pages/TheDriveType.vue'
import TheSpecifications from '../components/pages/TheSpecifications.vue'
import TheSpecification from '../components/pages/TheSpecification.vue'
import TheProduct from '../components/pages/TheProduct.vue'
import TheProductFeatures from '../components/pages/TheProductFeatures.vue'
import TheTechnics from '../components/pages/TheTechnics.vue'
import TheTechnicCreate from '../components/pages/TheTechnicCreate.vue'
import TheComponentCreate from "../components/pages/TheComponentCreate.vue"
import TheLogs from "../components/pages/TheLogs.vue"
import TheNews from "../components/pages/TheNews.vue"
import TheCategoryGroups from "../components/pages/TheCategoryGroups.vue";
import TheCategoryGroup from "../components/pages/TheCategoryGroup.vue";
import TheAttributes from "../components/pages/TheAttributes.vue";
import TheAttribute from "../components/pages/TheAttribute.vue";
import TheMeasures from "../components/pages/TheMeasures.vue";
import TheMeasure from "../components/pages/TheMeasure.vue";
import TheCoverage from "../components/pages/TheCoverage.vue"
import TheComponentCoverage from "../components/pages/TheComponentCoverage.vue"
import TheAutoCoverage from "../components/pages/TheAutoCoverage.vue"
import TheSearchStatistic from "../components/pages/TheSearchStatistic.vue"

const routes = [
    {
        path: '/',
        name: 'Login',
        component: TheLoginPage,
        meta: {
            layout: 'TheExternal',
            header: 'Войти'
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: TheForgotPassword,
        meta: {
            layout: 'TheExternal',
            header: 'Восстановление пароля'
        }
    },
    {
        path: '/admin',
        name: 'Dashboard',
        component: TheTechnics,
        meta: {
            layout: 'TheInternal',
            header: 'Техника',
            breadCrumbs: [
                {
                    title: 'Техника',
                    linkName: null,
                    current: true
                }
            ]
        },
    },
    {
        path: '/admin/technic/edit/:id?',
        name: 'TechnicCreate',
        component: TheTechnicCreate,
        meta: {
            layout: 'TheInternal',
            header: 'Добавить модификацию',
            breadCrumbs: [
                {
                    title: 'Техника',
                    linkName: 'Dashboard',
                    current: false
                },
                {
                    title: 'Добавить модификацию',
                    linkName: null,
                    current: true
                },
            ]
        },
    },
    {
        path: '/admin/technic/:technicId/component/edit/:id?',
        name: 'ComponentCreate',
        component: TheComponentCreate,
        meta: {
            layout: 'TheInternal',
            header: 'Добавить узел',
            breadCrumbs: [
                {
                    title: 'Техника',
                    linkName: 'Dashboard',
                    current: false
                },
                {
                    title: 'Добавить узел',
                    linkName: null,
                    current: true
                },
            ]
        },
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: TheUsers,
        meta: {
            layout: 'TheInternal',
            header: 'Пользователи',
            breadCrumbs: [
                {
                    title: 'Пользователи',
                    linkName: null,
                    current: true
                }
            ]
        },
    },
    {
        path: '/admin/api_keys',
        name: 'ApiKeys',
        component: TheApiKeys,
        meta: {
            layout: 'TheInternal',
            header: 'Доступы для сайтов и приложений',
            breadCrumbs: [
                {
                    title: 'Доступы для сайтов и приложений',
                    linkName: null,
                    current: true
                }
            ]
        },
    },
    {
        path: '/admin/qvs',
        name: 'Qvs',
        component: TheQvs,
        meta: {
            layout: 'TheInternal',
            header: 'Приоритеты продуктов по сцепкам',
            breadCrumbs: [
                {
                    title: 'Приоритеты продуктов по сцепкам',
                    linkName: null,
                    current: true
                }
            ]
        },
    },
    {
        path: '/admin/marketings',
        name: 'Marketings',
        component: TheMarketings,
        meta: {
            layout: 'TheInternal',
            header: 'Маркетинговое позиционирование',
            breadCrumbs: [
                {
                    title: 'Маркетинговое позиционирование',
                    linkName: null,
                    current: true
                }
            ]
        },
    },
    {
        path: '/admin/specifications',
        name: 'Specifications',
        component: TheSpecifications,
        meta: {
            layout: 'TheInternal',
            header: 'Спецификации',
            breadCrumbs: [
                {
                    title: 'Спецификации',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/make',
        name: 'Make',
        component: TheMake,
        meta: {
            layout: 'TheInternal',
            header: 'Марки',
            breadCrumbs: [
                {
                    title: 'Марки',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/make/edit/:id?',
        name: 'MakeEdit',
        component: TheMakeEdit,
        meta: {
            layout: 'TheInternal',
            header: 'Марки',
            breadCrumbs: [
                {
                    title: 'Марки',
                    linkName: 'Make',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/companies',
        name: 'Companies',
        component: TheCompanies,
        meta: {
            layout: 'TheInternal',
            header: 'Компании',
            breadCrumbs: [
                {
                    title: 'Компании',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/company/:id?',
        name: 'Company',
        component: TheCompany,
        meta: {
            layout: 'TheInternal',
            header: 'Компании',
            breadCrumbs: [
                {
                    title: 'Компании',
                    linkName: 'Companies',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/models',
        name: 'Models',
        component: TheModels,
        meta: {
            layout: 'TheInternal',
            header: 'Модели',
            breadCrumbs: [
                {
                    title: 'Модели',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/model/:id?',
        name: 'Model',
        component: TheModel,
        meta: {
            layout: 'TheInternal',
            header: 'Модели',
            breadCrumbs: [
                {
                    title: 'Модели',
                    linkName: 'Models',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/viscosity',
        name: 'Viscosity',
        component: TheViscosity,
        meta: {
            layout: 'TheInternal',
            header: 'Вязкости',
            breadCrumbs: [
                {
                    title: 'Вязкости',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/viscosity/edit/:id?',
        name: 'ViscosityEdit',
        component: TheViscosityEdit,
        meta: {
            layout: 'TheInternal',
            header: 'Вязкости',
            breadCrumbs: [
                {
                    title: 'Вязкости',
                    linkName: 'Viscosity',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/uses',
        name: 'Uses',
        component: TheUses,
        meta: {
            layout: 'TheInternal',
            header: 'Условия эксплуатации',
            breadCrumbs: [
                {
                    title: 'Условия эксплуатации',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/use/edit/:id?',
        name: 'Use',
        component: TheUse,
        meta: {
            layout: 'TheInternal',
            header: 'Условия эксплуатации',
            breadCrumbs: [
                {
                    title: 'Условия эксплуатации',
                    linkName: 'Uses',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/cool-types',
        name: 'CoolTypes',
        component: TheCoolTypes,
        meta: {
            layout: 'TheInternal',
            header: 'Типы охлаждения',
            breadCrumbs: [
                {
                    title: 'Типы охлаждения',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/cool-type/edit/:id?',
        name: 'CoolType',
        component: TheCoolType,
        meta: {
            layout: 'TheInternal',
            header: 'Типы охлаждения',
            breadCrumbs: [
                {
                    title: 'Типы охлаждения',
                    linkName: 'CoolTypes',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/category-groups',
        name: 'CategoryGroups',
        component: TheCategoryGroups,
        meta: {
            layout: 'TheInternal',
            header: 'Группы категорий',
            breadCrumbs: [
                {
                    title: 'Группы категорий',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/category-groups/edit/:id?',
        name: 'CategoryGroup',
        component: TheCategoryGroup,
        meta: {
            layout: 'TheInternal',
            header: 'Группы категорий',
            breadCrumbs: [
                {
                    title: 'Группы категорий',
                    linkName: 'CategoryGroups',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/attributes',
        name: 'Attributes',
        component: TheAttributes,
        meta: {
            layout: 'TheInternal',
            header: 'Аттрибуты',
            breadCrumbs: [
                {
                    title: 'Аттрибуты',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/attributes/edit/:id?',
        name: 'Attribute',
        component: TheAttribute,
        meta: {
            layout: 'TheInternal',
            header: 'Аттрибут',
            breadCrumbs: [
                {
                    title: 'Аттрибуты',
                    linkName: 'Attributes',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/measures',
        name: 'Measures',
        component: TheMeasures,
        meta: {
            layout: 'TheInternal',
            header: 'Единицы измерения',
            breadCrumbs: [
                {
                    title: 'Единицы измерения',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/measures/edit/:id?',
        name: 'Measure',
        component: TheMeasure,
        meta: {
            layout: 'TheInternal',
            header: 'Единица измерения',
            breadCrumbs: [
                {
                    title: 'Единицы измерения',
                    linkName: 'Measures',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/engine-builds',
        name: 'EngineBuilds',
        component: TheEngineBuilds,
        meta: {
            layout: 'TheInternal',
            header: 'Модификации двигателя',
            breadCrumbs: [
                {
                    title: 'Модификации двигателя',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/engine-build/edit/:id?',
        name: 'EngineBuild',
        component: TheEngineBuild,
        meta: {
            layout: 'TheInternal',
            header: 'Модификации двигателя',
            breadCrumbs: [
                {
                    title: 'Модификации двигателя',
                    linkName: 'EngineBuilds',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/fuels',
        name: 'Fuels',
        component: TheFuels,
        meta: {
            layout: 'TheInternal',
            header: 'Топливо',
            breadCrumbs: [
                {
                    title: 'Топливо',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/fuel/edit/:id?',
        name: 'Fuel',
        component: TheFuel,
        meta: {
            layout: 'TheInternal',
            header: 'Топливо',
            breadCrumbs: [
                {
                    title: 'Топливо',
                    linkName: 'Fuels',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/gear-operations',
        name: 'GearOperations',
        component: TheGearOperations,
        meta: {
            layout: 'TheInternal',
            header: 'Типы трансмиссии',
            breadCrumbs: [
                {
                    title: 'Типы трансмиссии',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/gear-operation/edit/:id?',
        name: 'GearOperation',
        component: TheGearOperation,
        meta: {
            layout: 'TheInternal',
            header: 'Типы трансмиссии',
            breadCrumbs: [
                {
                    title: 'Типы трансмиссии',
                    linkName: 'GearOperations',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/drive-types',
        name: 'DriveTypes',
        component: TheDriveTypes,
        meta: {
            layout: 'TheInternal',
            header: 'Типы привода',
            breadCrumbs: [
                {
                    title: 'Типы привода',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/drive-type/edit/:id?',
        name: 'DriveType',
        component: TheDriveType,
        meta: {
            layout: 'TheInternal',
            header: 'Типы привода',
            breadCrumbs: [
                {
                    title: 'Типы привода',
                    linkName: 'DriveTypes',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/specification/:id',
        name: 'Specification',
        component: TheSpecification,
        meta: {
            layout: 'TheInternal',
            header: 'Спецификация',
            breadCrumbs: [
                {
                    title: 'Спецификации',
                    linkName: 'Specifications',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/products',
        name: 'Products',
        component: TheProducts,
        meta: {
            layout: 'TheInternal',
            header: 'Продукты',
            breadCrumbs: [
                {
                    title: 'Продукты',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/product_features',
        name: 'ProductFeatures',
        component: TheProductFeatures,
        meta: {
            layout: 'TheInternal',
            header: 'Особенности продуктов',
            breadCrumbs: [
                {
                    title: 'Особенности продуктов',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/product/:id',
        name: 'Product',
        component: TheProduct,
        meta: {
            layout: 'TheInternal',
            header: 'Редактирование',
            breadCrumbs: [
                {
                    title: 'Продукты',
                    linkName: 'Products',
                    current: false
                },
                {
                    title: 'Редактирование',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/log',
        name: 'UserLog',
        component: TheLogs,
        meta: {
            layout: 'TheInternal',
            header: 'Лог изменений',
            breadCrumbs: [
                {
                    title: 'Лог изменений',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/news',
        name: 'News',
        component: TheNews,
        meta: {
            layout: 'TheInternal',
            header: 'Новостная лента',
            breadCrumbs: [
                {
                    title: 'Новостная лента',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/coverage',
        name: 'Coverage',
        component: TheCoverage,
        meta: {
            layout: 'TheInternal',
            header: 'Аналитика запросов',
            breadCrumbs: [
                {
                    title: 'Аналитика запросов',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/component_coverage',
        name: 'ComponentCoverage',
        component: TheComponentCoverage,
        meta: {
            layout: 'TheInternal',
            header: 'Покрытие узлов',
            breadCrumbs: [
                {
                    title: 'Покрытие узлов',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/auto_coverage',
        name: 'AutoCoverage',
        component: TheAutoCoverage,
        meta: {
            layout: 'TheInternal',
            header: 'Покрытие автопарка',
            breadCrumbs: [
                {
                    title: 'Покрытие автопарка',
                    linkName: null,
                    current: true
                }
            ]
        }
    },
    {
        path: '/admin/search_statistic',
        name: 'SearchStatistic',
        component: TheSearchStatistic,
        meta: {
            layout: 'TheInternal',
            header: 'Статистика поиска',
            breadCrumbs: [
                {
                    title: 'Статистика поиска',
                    linkName: null,
                    current: true
                }
            ]
        }
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    const publicPages = ['Login', 'ForgotPassword'];
    const authRequired = !publicPages.includes(to.name);
    const authStore = useAuthStore();

    document.title = (to.meta.header || 'Админка') + ' | Лукойл';

    await authStore.getProfile().then((r) => {
        if (r.data.success === true && r.data.data.active === false) {
            authStore.clearAuthUser();
        }
    }).catch((e) => {
        authStore.clearAuthUser();
    })

    await authStore.getProfile;

    if (authRequired && !authStore.authUser) {
        next({name: 'Login'})
    } else if (!authRequired && authStore.authUser) {
        next({name: 'Dashboard'})
    } else if (
        authStore.authUser &&
        !authStore.hasRole(['admin', 'technic', 'technic_read']) &&
        to.name === 'Dashboard'
    ) {
        if (authStore.hasRole('product')) {
            next({name: 'Products'})
        } else if (authStore.hasRole('query_analysis')) {
            next({name: 'Coverage'})
        }
    } else {
        next()
    }
})

export default router;
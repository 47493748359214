import {defineStore} from 'pinia'
import {ref} from 'vue';
import axios from "axios";
import {useRoute} from "vue-router";

export const CoverageFilterStore = defineStore("coverageFilter", () => {
    const route = useRoute()
    const detail = ref(null)

    const isLoaded = ref(true)
    const isLoadedReactive = ref(true)
    const date = ref({dateStart: '', dateEnd: ''})
    const activeParamFilter = ref({})

    const total = ref(0)
    const infoFilter = ref([])
    const fullInfoFilter = ref([])
    const reactiveFilter = ref([])
    const file = ref()

    const getDefaultFilters = () => {
        activeParamFilter.value = {}

        getInfoCoverage()
        getFile()
        getDataReactiveFilter()
    }

    const getFile = async (param = {}) => {
        await axios.post('/admin/statistics/download', param)
            .then((response) => {
                file.value = response?.data?.data?.file_path || undefined
            }).catch((error) => {
                console.log(error);
            })
    }

    const getInfoCoverage = async (param = {}) => {
        isLoaded.value = false
        await axios.post('/admin/statistics/data', param)
            .then((response) => {
                if (response.data?.data && response.data?.data?.list.length) {
                    total.value = response.data?.data?.total
                    infoFilter.value = response.data?.data.list
                    fullInfoFilter.value = response.data?.data.list.map(a => Object.assign({}, a));
                    infoFilter.value.map(el => {
                        if (el['byDay']) delete el['byDay']
                        return {...el}
                    })
                }
                else {
                    total.value = 0
                    infoFilter.value = []
                    fullInfoFilter.value = []
                }
            }).catch((error) => {
                total.value = 0
                infoFilter.value = []
                fullInfoFilter.value = []
                console.log(error);
            })
        isLoaded.value = true
    }

    const getDataReactiveFilter = async (param = {}) => {
        isLoadedReactive.value = false
        await axios.post('/admin/statistics/filters', param)
            .then((response) => {
                reactiveFilter.value = response.data.data
            }).catch((error) => {
                reactiveFilter.value = []
                console.log(error);
            })
        isLoadedReactive.value = true
    }

    //Обнуление фильтра
    const resetFilter = () => {
        getDefaultFilters()
    }

    //Применение выбранных параметорв фильтра
    const applyParamFilter = () => {
        const data = Object.assign({}, activeParamFilter.value, date.value)

        getInfoCoverage(data)
        getFile(data)
    }

    //Изменение даты
    const changeDateFilter = (dateStart = '', dateEnd = '') => {
        if (dateStart && dateEnd) {
            date.value.dateStart = dateStart
            date.value.dateEnd = dateEnd
        }
        else {
            date.value.dateStart = ''
            date.value.dateEnd = ''
        }
    }

    //Активные параметры реактивного фильтра
    const addParamReactiveFilter = (code = '' , selects = []) => {
        activeParamFilter.value[code] = selects
        let flag = false
        Object.keys(activeParamFilter.value).forEach(key => {
            if (key !== 'fuel' && flag) {
                delete  activeParamFilter.value[key]
            }
            if (key === code) flag = true
        })
        getDataReactiveFilter(activeParamFilter.value)
    }

    const changeDetail = (el) => {
        detail.value = el
    }

    return {
        date,
        detail,
        isLoaded,
        isLoadedReactive,
        infoFilter,
        fullInfoFilter,
        reactiveFilter,
        activeParamFilter,
        file,
        changeDetail,
        resetFilter,
        getDefaultFilters,
        applyParamFilter,
        getDataReactiveFilter,
        addParamReactiveFilter,
        changeDateFilter
    }
})